main {
    width: 100%;
    background-color: white;

    h1,
    h2 {
        font-family: "Roboto Condensed";
        margin-top: 0;
    }

    .content {
        max-width: 1200px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;

        >div {
            flex: 1 1 300px;
            margin: 1em;

            >img {
                display: flex;
                margin: auto;
                max-width: 100%;
            }
        }
    }

    .carousel {
        display: grid;
        gap: 2em;
        overflow: auto;
        justify-content: space-between;
        padding-bottom: 2em;

        img {
            width: 350px;
        }
    }

    .carousel3 {
        grid-template-columns: 350px 350px 350px;
    }

    .carousel5 {
        grid-template-columns: 350px 350px 350px 350px 350px;
    }

    iframe {
        width: 100%;
        height: 500px;
    }

    .list {
        list-style: none;
        padding: 0;

        > li {
            border: 0;
            border-bottom: 1px;
            border-style: dotted;
            border-color: black;
            padding: .25em 0;

            &:last-child{
                border: 0;
            }
        }
    }

    .hero {
        display: flex;
        align-items: center;
    }
}